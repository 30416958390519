
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import formatUtil from "../../mixins/roundValues";

export default Vue.extend({
  name: "SeverityHemophiliaByGenderAndGNI",
  mixins: [formatUtil],
  data: () => ({
    data: [],
    isLoading: false,
    genders: [],
    genderSelected: [],
    severities: [],
    severitiesSelected: [],
    diseaseTypes: [],
    diseaseTypesSelected: [],
    payload: {
      year: null,
    },
  }),
  computed: {
    yearSelected() {
      return this.$store.getters["agsreport/getYearSelected"].year;
    },
    title() {
      return i18n.t(
        "reports.agsreports.severityHemophiliaByGenderAndGNI.title"
      );
    },
    headers() {
      return [
        // {
        //   text: i18n.t("reports.agsreports.severityHemophiliaByGenderAndGNI.n"),
        //   value: "n",
        // },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.surveyYear"
          ),
          value: "surveyYear",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.type"
          ),
          value: "type",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.gender"
          ),
          value: "genre",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.rank"
          ),
          value: "rank",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.severe"
          ),
          value: "severe",
          aling: "center",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.moderate"
          ),
          value: "moderate",
          aling: "center",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.mild"
          ),
          value: "mild",
          aling: "center",
        },
        {
          text: i18n.t(
            "reports.agsreports.severityHemophiliaByGenderAndGNI.unknown"
          ),
          value: "unknown",
          aling: "center",
        },
        {
          text: i18n.t("reports.agsreports.severityHemophiliaByGenderAndGNI.n"),
          value: "n",
          aling: "center",
        },
      ];
    },
  },
  methods: {
    exportData() {
      axios
        .get(`report/DDC/Severity-Hemophilia-by-Genre-and-GNI/export`, {
          params: { year: this.yearSelected },
          responseType: "blob",
        })
        .then((res) => {
          const fileName =
            `${i18n.t("reports.agsreports.pagetitle")} -` + this.title;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "application/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
    loadData() {
      this.isLoading = true;
      axios
        .get("report/DDC/Severity-Hemophilia-by-Genre-and-GNI", {
          params: { year: this.yearSelected },
        })
        .then((res: any) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    if (this.yearSelected) {
      this.loadData();
    }
  },
  watch: {
    yearSelected() {
      this.loadData();
    },
  },
});
